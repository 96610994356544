<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex flex-wrap align-items marginBottom20">

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width300">
              <el-input
                v-model="searchData.name"
                placeholder="输入会员编号/会员昵称/会员手机号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width250">
              <el-input
                v-model="searchData.car"
                placeholder="输入车型名称/车辆编号"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">优惠券名称: </span> -->
            <div class="width250">
              <el-input
                v-model="searchData.regionName"
                placeholder="输入区域名称"
                @keyup.enter.native="handleSearch"
                clearable
                maxlength="20"
              ></el-input>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <!-- <span class="span marginRight10">下单时间：</span> -->
            <div class="width200">
              <el-select
                class="width250"
                v-model="searchData.handleWay"
                clearable
                @change="handleSearch"
              >
                <el-option
                  value=""
                  label="请选择售后类型"
                ></el-option>
                <el-option
                  :value="2"
                  label="退款"
                ></el-option>
                <el-option
                  :value="1"
                  label="换车"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">申请时间：</span>
            <div class="width300">
              <el-date-picker
                v-model="searchData.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleSearch"
                clearable
              >
              </el-date-picker>
            </div>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
            >查询</el-button>
          </div>

          <div class="flex align-items marginRight10 marginBottom10">
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
            >重置</el-button>

          </div>
          <div class="flex align-items marginRight10 marginBottom10">
          <el-button
            size="medium"
            type="primary"
            @click="handleExport"
            class="marginLeft10 marginBottom10"
            >导出</el-button
          >
        </div>
          <div class="flex align-items marginRight10 marginBottom20">
            <!-- <el-button
              size="medium"
              type="primary"
              icon="el-icon-plus"
              @click="handleCreate"
            >新建</el-button> -->
          </div>

        </div>
      </template>
      <template
        slot="index"
        slot-scope="row"
      >
        <div>{{ page.total - ((page.currentPage - 1) * page.pageSize + row.index) }}</div>
      </template>

      <template
        slot="handleSuggest"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          @click="onCarTypeDescHandle(row)"
        >查看</el-button>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          v-if="row.state ===1"
          type="text"
          size="mini"
          @click="handleEdit(row)"
        >填写处理意见</el-button>
        <el-button
          v-if="row.state ===2 || row.state ===4"
          type="text"
          size="mini"
          @click="handleIdeaHandle(row)"
        >审核</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleDetails(row)"
        >查看处理</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      title="填写意见"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        @submit="submit"
        :option="formOption"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-after="uploadAfter"
        :upload-before="uploadBefore"
      >

        <template slot="handleSuggest">
        <div style="display: flex;">
          <span style="color: #f66c6c;margin-right: 15px;">*</span>
          <el-input
            v-model="form.handleSuggest"
            placeholder="请输入处理意见"
            type="textarea"
            maxlength="200"
            :rows="4"
          />
        </div>
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">取消</el-button>
          <el-button
            @click="submit(form,1)"
            :disabled="commitStateLoading2"
            :loading="commitStateLoading1"
          >保存草稿</el-button>
          <el-button
            @click="submit(form,2)"
            type="primary"
            :disabled="commitStateLoading1"
            :loading="commitStateLoading2"
          >提交</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      title="处理意见"
      :visible.sync="dialogTableVisibleUse"
    >
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="dialogTableVisibleUse = false"
        >确 定</el-button>
      </div>
      <div>
        {{ CarTypeDescData }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateSuggest, list } from "@/api/fault/manage";
import dayjs from "dayjs";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      commitStateLoading1: false,
      commitStateLoading2: false,
      searchData: {
        name: "",
        car: "",
        handleWay: "",
        regionName: "",
        createTime: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      CarTypeDescData: {},
      tableData: [],
      showLoading: false,
      option: {
        title: "故障列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        // index: true, // 显示索引
        // indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
        {
            label: "序号",
            prop: "index",
          },
          {
            label: "故障编号",
            prop: "code",
          },
          {
            label: "故障类型",
            prop: "faultTypeStr",
            // formatter: function (row, column, cellValue) {
            //   return row.type === 1
            //     ? "用户上报"
            //     : row.type === 2
            //     ? "系统自测"
            //     : row.type === 3
            //     ? "员工上报"
            //     : row.type;
            // },
          },
          {
            label: "会员编号",
            prop: "userCode",
          },
          {
            label: "会员昵称",
            prop: "nickname",
          },
          {
            label: "会员手机号",
            prop: "phone",
          },
          // {
          //   label: "售后类型",
          //   prop: "handleWay",
          //   formatter: function (row, column, cellValue) {
          //     return row.handleWay === 1
          //       ? "换车"
          //       : row.handleWay === 2
          //       ? "退款"
          //       : '-';
          //   },
          // },
          {
            label: "申请时间",
            prop: "createTime",
          },
          {
            label: "车型名称",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
          },
          {
            label: "所属区域",
            prop: "regionName",
          },
          {
            label: "处理意见",
            prop: "handleSuggest",
          },
          {
            label: "故障描述",
            prop: "faultDesc",
          },
          {
            label: "处理状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1
                ? "未填写意见"
                : row.state === 2
                ? "意见待审核"
                : row.state === 3
                ? "待处理"
                : row.state === 4
                ? "待审核"
                : row.state === 5
                ? "已处理"
                : row.state;
            },
          },
        ],
      },
      dialogTableVisibleUse: false,
      dialogVisible: false,
      form: {
        id: "",
        handleSuggest: "",
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: "",
            prop: "handleSuggest",
            span: 24,
            maxlength: 200,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入处理意见",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.createTimeStart &&
        searchForm.searchData.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData.createTimeStart,
          searchForm.searchData.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  mounted() {
    const that = this;
    // window.setInterval(() => {
    //   setTimeout(() => {
    //     that.onLoad();
    //   }, 100);
    // }, 30000);
  },
  methods: {
    onTravelTime() {
      if (
        this.form.lowGearSpeed &&
        this.form.highGearSpeed &&
        this.form.travel
      ) {
        this.form.travelTime = this.form.travel;
      } else {
        this.form.travelTime = "";
      }
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            if (t === "travel" && value < 1) {
              this.form[t] = 1;
              e.target.value = 1;
              return;
            }
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    salaryDianChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(1);
            e.target.value = max.toFixed(1);
          } else {
            this.form[t] = parseFloat(value).toFixed(1);
            e.target.value = parseFloat(value).toFixed(1);
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        name: this.searchData.name,
        car: this.searchData.car,
        handleWay: this.searchData.handleWay,
        regionName: this.searchData.regionName,
      };
      if (this.searchData.createTime) {
        searchData.createTimeStart = dayjs(
          this.searchData.createTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.createTimeEnd = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }

      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify({ ...searchForm }));

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      // console.log('添加')
      this.dialogVisible = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      localStorage.removeItem("searchForm");
      // console.log('重置')
      this.searchData = {
        name: "",
        car: "",
        handleWay: "",
        regionName: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑',row)
      // const { data } = await view({
      //   id: row.id,
      // });
      // console.log(data)
      this.form.id = row.id;
      this.form.handleSuggest = row.handleSuggest;
      // this.form.travel = this.form.travelTime;
      this.dialogVisible = true;
    },
    // 审核
    handleIdeaHandle(row) {
      this.$router.push({
        path: "/fault/manage/ideaHandle",
        query: {
          type: "details",
          id: row.id,
        },
      });
    },
    // 查看处理
    handleDetails(row) {
      this.$router.push({
        path: "/fault/manage/details",
        query: {
          type: "details",
          id: row.id,
        },
      });
    },
    //查看区域
    onCarTypeDescHandle(row) {
      this.dialogTableVisibleUse = true;
      this.CarTypeDescData = row.handleSuggest;
    },

    // 提交
    submit(form, commitState) {
      if (commitState === 1) {
        this.commitStateLoading1 = true;
      }
      if (commitState === 2) {
        this.commitStateLoading1 = true;
      }
      this.$refs.form.validate((valid, done, msg) => {
        if (valid) {
          updateSuggest({ ...form, commitState })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.dialogVisible = false;
                setTimeout(() => {
                  this.resetForm();
                }, 500);
                this.onLoad();
              } else {
                this.$message.error(res.msg);
              }
              done();
              this.commitStateLoading1 = false;
              this.commitStateLoading1 = false;
            })
            .catch((error) => {
              done();
              this.commitStateLoading1 = false;
              this.commitStateLoading1 = false;
            });
        } else {
          this.commitStateLoading1 = false;
          this.commitStateLoading1 = false;

          done();
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        handleSuggest: "",
      };
    },
    uploadDelete(file, column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      if (!res.path) {
        let msg = JSON.parse(JSON.stringify(res)).message;
        if (msg.indexOf("401") > -1) {
          this.$message.error("登录失效，请重新登录");
          this.$router.push("/login");
        } else {
          this.$message.error("上传失败");
        }
        loading();
      } else {
        column.propsHttp.name = res.path;
      }
    },
    handleExport() {
  
      const data = {
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      };
      window.open(
          `/api/web/fault/manage/export?${qs.stringify(data)}`,
          "_self"
        );
    },
  },
};
</script>

<style scoped>
.travel .travel-list {
  width: 33%;
  text-align: center;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.travel .travel-list:last-child {
  border-right: 1px solid #999;
}

.travel .travel-list .travel-list-title {
  border-bottom: 1px solid #999;
}

.avue-crud .el-input--small input,
::v-deep .avue-form .travel .travel-list .el-input--small input {
  border: none;
}
</style>